<template>
  <NavigationBar/>
    <div class="contact">
      <h1>Fyll ut skjemaet så tar vi kontakt!</h1>
      <!-- <ContactForm /> -->
    </div>
  </template>
  
  <script>
//import ContactForm from '@/components/ContactForm.vue'
import NavigationBar from '@/components/NavigationBar.vue'

export default {
  name: 'ContactView',
  components: {
    NavigationBar
    //ContactForm,
  }
}
</script>

<style>

</style>