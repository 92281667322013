<template>
    <header :class="{ 'scrolled-nav': scrolledNav }">
        <nav>
           <div class="branding">
                <!-- <img src="@/assets/Logo.png" alt=""/> -->
                <img alt="Vue logo" src="../assets/logo.png">
            </div>
            <ul v-show="!mobile" class="navigation">
                <li><router-link class="link" to="/">Hjem</router-link></li>
                <li><router-link class="link" to="/about">Tjenester</router-link></li>
                <li><router-link class="link" to="/contact">Kontakt</router-link></li>
            </ul>
            
        <!--   <div> Icons made by <a href="https://www.flaticon.com/authors/pixel-perfect" title="Pixel perfect"> Pixel perfect </a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com'</a></div> -->
            <div class="icon">
                <i @click="toggleMobileNav" v-show="mobile" :class="{'icon-active' : mobileNav}">
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0,0,256,256">
                        <!--  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0,0,256,256">-->
                        <g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(5.12,5.12)"><path d="M5,8c-0.72127,-0.0102 -1.39216,0.36875 -1.75578,0.99175c-0.36361,0.623 -0.36361,1.39351 0,2.01651c0.36361,0.623 1.0345,1.00195 1.75578,0.99175h40c0.72127,0.0102 1.39216,-0.36875 1.75578,-0.99175c0.36361,-0.623 0.36361,-1.39351 0,-2.01651c-0.36361,-0.623 -1.0345,-1.00195 -1.75578,-0.99175zM5,23c-0.72127,-0.0102 -1.39216,0.36875 -1.75578,0.99175c-0.36361,0.623 -0.36361,1.39351 0,2.01651c0.36361,0.623 1.0345,1.00195 1.75578,0.99175h40c0.72127,0.0102 1.39216,-0.36875 1.75578,-0.99175c0.36361,-0.623 0.36361,-1.39351 0,-2.01651c-0.36361,-0.623 -1.0345,-1.00195 -1.75578,-0.99175zM5,38c-0.72127,-0.0102 -1.39216,0.36875 -1.75578,0.99175c-0.36361,0.623 -0.36361,1.39351 0,2.01651c0.36361,0.623 1.0345,1.00195 1.75578,0.99175h40c0.72127,0.0102 1.39216,-0.36875 1.75578,-0.99175c0.36361,-0.623 0.36361,-1.39351 0,-2.01651c-0.36361,-0.623 -1.0345,-1.00195 -1.75578,-0.99175z"></path></g></g></svg>
                </i>
             <!--   <i @click="toggleMobileNav" v-show="mobile" class="fa-regular fa-star" :class="{'icon-active' : mobileNav}"> <img src="@/assets/images/menu.png" alt=""/></i> -->
            <!--  <i @click="toggleMobileNav" v-show="mobile" :class="{'icon-active' : mobileNav}"><CIcon :icon="cilHamburgerMenu" size="sm"/></i> -->
            <!--    <CIcon :icon="cilHamburgerMenu" size="sm"/> -->
         <!--         <span class="material-symbols-outlined">menu</span> -->
        <!--         <img src="@/assets/images/menu.png" alt=""/> -->
      <!--         <i @click="toggleMobileNav" v-show="mobile" class="fa-regular fa-star" :class="{'icon-active' : mobileNav}"></i> -->
      <!--cilHamburgerMenu-->
            </div>
            <transition name="mobile-nav">
                <ul v-show="mobileNav" class="dropdown-nav">
                    <li><router-link class="link" to="/">Hjem</router-link></li>
                    <li><router-link class="link" to="/about">Tjenester</router-link></li>
                    <li><router-link class="link" to="/contact">Kontakt</router-link></li>
                </ul>
            </transition>
            
        </nav>
    </header>
  </template>
  
<script>
//import { CIcon } from '@coreui/icons-vue';
//import { cilHamburgerMenu } from '@coreui/icons';
export default {
    name: "NavigationBar",
    components: {
       // CIcon
    },
 //   setup() {
 //       return {
 //          // cilHamburgerMenu,
//        }
//    },
    data() {
        return {
            scrollPosition: null,
            mobile: null,
            mobileNav: null,
            windowWidth: null,
        };
    },
    created() {
        window.addEventListener('resize', this.checkScreen);
        this.checkScreen();
    },
    mounted() {
        window.addEventListener('scroll', this.updateScroll)
    },
    methods: {
        toggleMobileNav() {
            this.mobileNav = !this.mobileNav;
        },

        updateScroll() {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 50) {
                this.scrolledNav = true;
                return;
            }
            this.scrolledNav = false;
        },

        checkScreen() {
        this.windowWidth = window.innerWidth;
        if (this.windowWidth <= 750) {
            this.mobile = true;
            return;
        }
        this.mobile = false;
        this.mobileNav = false;
        return;
        }
    },
    
};
</script>
  
<style lang="scss" scoped>
header {
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 99;
    width: 100%;
    position: fixed;
    transition: .5s ease all;
    color: #fff;
    //top: 0;
    //height: 75px;

    nav {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 12px 0;
    transition: .5s ease all;
    width: 90%;
    margin: 0 auto;
    @media(min-width: 1140px) {
        max-width: 1140px;
    }

    ul,
    .link {
        font-weight: 500;
        color: #fff;
        list-style: none;
        text-decoration: none;
    }

    li {
        text-transform: uppercase;
        padding: 16px;
        margin-left: 16px;
    }

    .link {
        font-size: 14px;
        transition: .5s ease all;
        padding-bottom: 1px solid transparent;
        text-decoration: none;

        &:hover {
            color: #00afea;
            border-color: #00afea;
            cursor: pointer;

        }
    }

    .branding {
        display: flex;
        align-items: center;

        img {
            width: 50px;
            transition: .5s ease all;
        }
    }

    .navigation {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: flex-end;
    }

    .icon {
        display: flex;
        align-items: center;
        position: absolute;
        //top: 40px;
        right: 24px;
        //height: 30%;

        i {
            cursor: pointer;
            font-size: 24px;
            transition: 0.8s ease all;
        }
    }

    .icon-active {
        transform: rotate(180deg);
    }

    // Remove left space on navigation
    ul {
            padding: 0;
    }

    // Side navigation Mobile
    .dropdown-nav {
        display: flex;
        flex-direction: column;
        position: fixed;
        width: 100%;
        max-width: 250px;
        height: 100%;
        background-color: #fff;
        top: 0;
        left: 0;

        li {
            margin-left: 0;
            .link {
                color: #000;

            }
        }

    }
    .mobile-nav-enter-active,
    .mobile-nav-leave-active {
        transition: 1s ease all;
    }

    .mobile-nav-enter-from,
    .mobile-nav-leave-to {
        transform: translateX(-250px);
    }

    .mobile-nav-enter-to {
        transform: translateX(0);
    }
 
}
}

.scrolled-nav {
    background-color: #000;
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);

    nav {
        padding: 8px 0;

        .branding {
            img {
                width: 40px;
                box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);
            }
        }
    }
}



</style>