<template>
  <div class="app">
  <!-- <NavigationBar/> -->
   <router-view/> 
  </div>
</template>

<script>
// @ is an alias to /src
//import NavigationBar from '@/components/NavigationBar.vue'

export default {
  name: 'App',
  components: {
   //NavigationBar,
  }
}
</script>


<style lang="scss">
// Remove thin border (margin) framing the page
 * {
       margin: 0;
   }

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>
