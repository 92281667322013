import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
//import { Email } from '@/assets/smtp/smtp.js';


createApp(App).use(router).mount('#app')



