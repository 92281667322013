<template>
    <img alt="Man Climbing Mountain" src="@/assets/images/man-climbing-mountaint.webp">
      <h1>{{ msg }}</h1>
  </template>
  
  <script>
  export default {
    name: 'HeroSection',
    props: {
      msg: String
    }
  }
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

img {
    object-fit: cover;
    width: 100%;
}
  </style>
  