<template>
  <NavigationBar/>
  <HeroSection/>

  <div class="content">
   <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
   <div class="wrapper">
    <img alt="Vue logo" src="../assets/logo.png">
   </div>
   <div class="info">
    <h3>Skreddersydde nettverksløsninger</h3>
    <p>Vi forstår at hver bedrift har unike behov. Derfor tilbyr vi skreddersydde nettverksløsninger som passer for din virksomhet. Uansett om det er en restaurant med mange samtidige brukere eller et kontor med behov for sikker og stabil tilkobling, er vi her for å sammen med deg finne den beste løsningen.</p>
   </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeroSection from '@/components/HeroSection.vue'
import NavigationBar from '@/components/NavigationBar.vue'

export default {
  name: 'HomeView',
  components: {
    HeroSection,
    NavigationBar
  }
}
</script>

<style>
.content {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0;

}

.wrapper {
  flex: 1;
  height: 460px;
  margin: 0;
  position: relative;
}

.info {
  width: 430px;
}

</style>
